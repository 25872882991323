@import '../../../../../assets/styles/index';

.priceRange {
  @include main;
  .price {
    color: $green;
  }
  .unit {
    color: $gray;
  }
}