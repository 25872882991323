@import '../../../assets/styles/index';

.enrichmentList {
  min-height: 220px;
  .serviceCount {
    @include h4;
    color: $gray;
    margin-bottom: 16px;
    display: flex;
    gap: 4px;
  }
  .noData {
    @include main;
    color: $main;
    margin-top: 20px;
  }

  .swiper-container {
    .swiper-slide {
      padding-bottom: 8px;
    }

    .swiper-button {
      width: 46px;
      height: 46px;
      border-radius: 52px;
      background-color: $accentBlue;

      &::after {
        content: '';
      }
    }
  }
}

.categoryFilter {
  @include main;
  color: $main;
  background-color: $white;
  z-index: 100;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  border: 1px solid $strokeBlue;
  border-radius: 8px;
  box-shadow: 3px 4px 9px 0 rgba(144, 143, 166, 0.25);
}


.filterBtn {
  width: 39px !important;
  height: 39px !important;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  &.active {
    border-color: $accentBlue !important;
  }
}