@import './colors';
@import './fonts';

//sizes

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.w-50 {
  width: 50%;
}

//colors

.lightGrayBG {
  background: $strokeLightGray;
}

//text

.main-text {
  @include main;
  color: $main;
}

.subTitle {
  @include main;
  color: $gray;
}

.hintText {
  @include tag;
  color: $accentBlue;
}

.h1 {
  @include h1;
  color: $heading;
  margin-top: 0;
  margin-bottom: 0;
}

.h2 {
  @include h2;
  color: $heading;
  margin-top: 0;
  margin-bottom: 0;
}

.h3 {
  @include h3;
  color: $heading;
  margin-top: 0;
  margin-bottom: 0;
}

.h4 {
  @include h4;
  color: $heading;
  margin-top: 0;
  margin-bottom: 0;
}

.tag {
  @include tag;
  color: $gray;
}

.btn {
  @include button;
  color: $accentBlue;
}

.bold {
  font-weight: 500;
}

.lowercase {
  text-transform: lowercase;
}

//aligns

.alignCenter {
  display: flex;
  align-items: center;
}

.justifyCenter {
  display: flex;
  justify-content: center;
}

.justifySpaceBetween {
  display: flex;
  justify-content: space-between;
}

.positionRelative{
  position: relative;
}

//spaces

.first-space-item-full {
  width: 100%;

  > .ant-space-item:first-child {
    width: 100%;
  }
}

.second-space-item-full {
  width: 100%;

  > .ant-space-item:nth-child(2) {
    width: 100%;
  }
}

.space-items-full {
  width: 100%;

  .ant-space-item {
    width: 100%;
  }
}

.noBottomMargin {
  margin-bottom: 0;
}

.noBottomMarginForm {
  .ant-form-item {
    margin-bottom: 0;
  }
}

.noPadding {
  padding: 0 !important;
}

.mt-8 {
  margin-top: 8px !important;
}

.mb-16 {
  margin-bottom: 16px !important;
}

.mb-24 {
  margin-bottom: 24px !important;
}

.mt-24 {
  margin-top: 24px !important;
}

.p-16 {
  padding: 16px !important;
}

.pt-8 {
  padding-top: 8px !important;
}

.pb-8 {
  padding-bottom: 8px !important;
}

.pt-16 {
  padding-top: 16px !important;
}

.pb-16 {
  padding-bottom: 16px !important;
}

.pb-24 {
  padding-bottom: 24px !important;
}


// Text overflow and wrapping

.textWrap {
  white-space: wrap;
}

.textNoWrap {
  white-space: nowrap;
}

//ant-btn

.ant-btn {
  @include button;

  &:not(.reset-padding) {
    padding: 18px 15px;
  }

  &-primary {
    background-color: $accentBlue;
    color: $white;
  }

  &-default {
    &:not(.ant-btn-dangerous) {
      color: $accentBlue;
    }
  }

  &-link {
    color: $accentBlue;
  }

  &:disabled {
    color: rgba(0,0,0,0.25);
    .ant-btn-icon {
      opacity: 0.5;
    }
  }
}

//ant-checkbox

.ant-checkbox:not(.ant-checkbox-disabled) .ant-checkbox-inner,
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled) .ant-checkbox-checked .ant-checkbox-inner {
  border-color: $accentBlue;
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled) .ant-checkbox-checked .ant-checkbox-inner {
  background-color: $accentBlue;
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner {
  background-color: transparent !important;
  border-color: $accentBlue !important;
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled) .ant-checkbox-checked:hover .ant-checkbox-inner {
  background-color: $accentBlue !important;
  border-color: $accentBlue !important;
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked .ant-checkbox-inner {
  background-color: $accentBlue !important;
  border-color: $accentBlue !important;
}

//ant-select

.ant-select,
.ant-select-multiple {
  @include main;

  &:not(.ant-select-status-error) {
    &:hover .ant-select-selector,
    &.ant-select-focused .ant-select-selector,
    &.ant-select:focus .ant-select-selector {
      border-color: $accentBlue !important;
    }
  }
}

//ant-input

.ant-input-number:not(.ant-input-status-error):hover,
.ant-input-outlined:not(.ant-input-status-error):focus,
.ant-input-outlined:not(.ant-input-status-error):focus-within,
.ant-input-outlined:not(.ant-input-status-error):hover,
.ant-input-number-focused:not(.ant-input-status-error),
.ant-input-number:not(.ant-input-status-error):focus {
  border-color: $accentBlue !important;
}


//ant-collapse

.ant-collapse {
  .ant-collapse-item {
    .ant-collapse-header {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      align-items: center !important;
    }

    &.ant-collapse-item-active {
      .ant-collapse-header {
        padding-bottom: 24px !important;
      }
    }
  }
  .ant-collapse-content-box {
    padding-top: 0 !important;
  }
  .ant-collapse-header, .ant-collapse-content-box {
    padding-left: 0 !important;
    padding-right: 0 !important;
    .ant-collapse-header-text {
      @include h3;
      color: $heading;
    }
    .ant-collapse-expand-icon {
      path {
        color: $heading;
      }
    }
  }
}

//swiper

.swiper-slide {
  cursor: grab;
}

.swiper-slide:active {
  cursor: grabbing;
}

//ant-modal

//.ant-modal .ant-modal-footer {
//  margin-top: 24px;
//}

.ant-modal-footer {
  display: flex;
  gap: 8px;
  .ant-btn {
    flex: 1;
  }
}

//ant-form

.ant-form-item {
  margin-bottom: 0;
}

.ant-form-item, .ant-form-item .ant-form-item-label >label, .ant-checkbox-wrapper, .ant-input-number {
  @include main;
  color: $main !important;
}

.ant-form-item-required {
  width: 100%;
}

//and-badge

.premium-badge{
  //width: 100% !important;
  display: flex;
  align-items: center;
}

//ant-layout

.ant-layout {
  background-color: $white;
}

.ant-layout-content {
  //min-height: 100vh;
  background-color: $white;
  padding: 40px 40px;
  @media (max-width: 992px) {
    padding: 20px;
  }
  @media (min-width: 1200px) {
    padding: 40px 60px;
  }
  @media (min-width: 1600px) {
    padding: 40px 120px;
  }
}

//a

a {
  color: $accentBlue;
}

//ant-switch

.ant-switch.ant-switch-checked {
  background: $accentBlue !important;
}

//ant-tabs

.ant-tabs-tab-btn {
  @include h4;
}

//ant-picker

@media (max-width: 768px) {
  .ant-picker-dropdown {
    display: flex;
    justify-content: center;
    .ant-picker-panel-container {
      margin: 0 auto !important;
      .ant-picker-datetime-panel {
        flex-direction: column;
      }
    }
  }
}


//upload

.base-service-upload {
  display: flex;
  .ant-btn {
    padding: 0 !important;
  }
}

.categories-select {
  .ant-select-selection-item {
    align-items: baseline;
  }
}

.error-message {
  color: $red;
  margin: 4px 0 8px 0;
}

.profile-avatar {
  background-color: $accentBlue !important;
}

//ant-menu

.ant-menu-item-selected {
  background-color: unset !important;
}

.ant-menu-item-selected::after {
  border-bottom-width: 2px !important;
  border-bottom-color: $white !important;
}

.ant-menu-submenu-selected {
  background-color: unset !important;
}

.ant-menu-submenu-selected:hover {
  background-color: unset !important;
}

.ant-menu-submenu-title {
  display: flex !important;
  align-items: center !important;
}

.upcomingInvoiceCard {
  @media (max-width: 992px) {
    border: none;
    .ant-card-body {
      padding: 0;
    }
  }
}

.statistic-card {
  width: 100%;
  border: 1px solid #AAC3EE;
  border-radius: 8px;
  height: 100%;
  .ant-card-body {
    padding: 16px 24px;
  }
  .ant-statistic {
    .ant-statistic-content {
      color: $accentBlue !important;
    }
  }
}

.integrations-card {
  .ant-card {
    display: flex;
    flex-direction: column;
    height: 100%;
    .ant-card-body {
      flex: 1;
    }
    .ant-card-actions {
      @media (max-width: 992px) {
        flex-direction: column;
        align-items: center;
        li {
          border-inline-end: none;
          width: 100% !important;
          margin: 12px !important;
          &:not(:last-child) {
            border-bottom: 1px solid #f0f0f0;
            padding-bottom: 12px !important;
            margin-bottom: 6px !important;
          }
        }
      }
    }
  }
}

.advancedParametersPanel {
  .ant-collapse-header-text {
    white-space: normal;
    word-wrap: break-word;
    overflow-wrap: break-word;
    max-width: 100%;
  }
}

.queries-input, .categories-select {
  .ant-form-item-required {
    display: flex;
    align-items: baseline !important;
  }
}

.trafficStartStopLocation {
  width: 100%;
  padding: 12px 16px;
  border: 1px solid $strokeLightGray;
  border-radius: 8px;
  box-shadow: 3px 4px 9px 0 rgba(144, 143, 166, 0.25);
  align-items: flex-end;
  .ant-space-item:first-child, .ant-space-item:nth-child(2) {
    width: 100%;
  }
}

.paymentMethodCardTitle {
  width: 100%;
  padding: 8px 0;
  justify-content: space-between;
  > .ant-space-item:first-child {
    width: 100%;
  }
  @media (max-width: 992px) {
    > .ant-space-item:first-child {
      width: unset;
    }
    > .ant-space-item:nth-child(2) {
      width: 100%;
    }
  }
}

.loginSignupForm {
  width: 350px;
  margin: 50px auto;
  .loginWithBtn {
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    color: #3c4043;
    width: 100%;
  }
}
