@import '../../../assets/styles/index';

.custom-alert {
  display: flex;
  align-items: center;
  border-radius: 6px;
  padding: 8px;
  gap: 8px;
  width: 100%;
  @include main;
  color: $heading;

  &__message {
    flex: 1;
  }

  &--warning {
    border: 1px solid #ffe58f;
    background: #fffbe6;
  }

  &--attention {
    border: 1px solid #baddff;
    background: #e7f3ff;
  }

  &--error {
    border: 1px solid #FFCCC7;
    background: #fff2f0;
  }
}

