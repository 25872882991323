@import '../../../../../assets/styles/index';

.serviceCard {
  display: flex;
  flex-direction: column;
  padding: 0 12px 12px;
  border: 1px solid $strokeBlue;
  border-radius: 8px;
  box-shadow: 3px 4px 9px 0 rgba(144, 143, 166, 0.25);

  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 8px;
    height: 51px;
    .title {
      @include h4;
      width: 100%;
      color: $heading;
      cursor: pointer;
      padding: 12px 0;

    }
    .actions.disabled .icon {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 8px;
    .body {
      @include main;
      color: $main;
      height: 70px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      word-break: break-word;
      }

    .footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .open-link-btn {
        padding: 0 !important;
        height: 18px;
      }
    }

    .divider {
      margin: 8px 0;
    }
  }

  .icon {
    cursor: pointer;
  }
}
