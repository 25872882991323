@import '../../assets/styles/index';

.ant-layout-header{
  padding: 0 40px;
  @media (max-width: 992px) {
    padding: 0 20px;
  }
  @media (min-width: 1200px) {
    padding: 0 60px;
  }
  @media (min-width: 1600px) {
    padding: 0 120px;
  }
}

.back-icon.disabled {
  opacity: 0.2;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;

  .burger-menu {
    font-size: 20px;
    color: $white;
  }

  .logo {
    display: flex;
  }

  .account-menu {
    min-width: 150px;
    justify-content: flex-end;
    .ant-menu-title-content {
      color: $white;
      max-width: 80px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .menu {
    .menu-item {
      @include h4;
      color: $white;
      opacity: 0.8;
      cursor: pointer;
      padding: 6px;

      &:hover {
        opacity: 1;
      }

      a {
        color: unset;
      }
    }
  }

}

.ant-drawer {
  .ant-drawer-content-wrapper {
    width: 320px;
  }

  .main-menu {
    .ant-menu-submenu-arrow {
      transform: rotate(-90deg);
    }
  }

  .ant-menu-submenu {
    .ant-menu-submenu-title {
      @include h4;
      line-height: 40px;
      color: $heading;
      padding-left: 8px !important;
      padding-right: 8px !important;
    }
  }
  .ant-menu {
    border-inline-end: unset !important;
    .ant-menu-item {
      @include h4;
      line-height: 40px;
      color: $heading;
      padding-left: 8px !important;
      padding-right: 8px !important;
    }
  }
  .favorites-recent-menu {
    margin-top: 16px;
    padding-top: 16px;
    border-top: 1px solid $strokeGray;
    .ant-menu-submenu-open {
      .ant-menu-submenu-title {
        font-weight: 500;
      }
    }
    .ant-menu-sub {
      margin-left: 24px;
      background: unset !important;
    }
  }
  .drawer-title {
    cursor: pointer;
    .back-icon {
      path {
        fill: $heading;
      }
    }
  }
  .ant-drawer-header-title {
    flex-direction: row-reverse !important;
  }

  .account-menu {
    @media (max-width: 992px) {
      border-bottom: 1px solid $strokeGray;
      margin-bottom: 8px;
      padding-bottom: 8px;
      .ant-menu-sub {
        background: unset !important;
      }
    }
  }

}