body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline;
  margin: 0;
  padding: 0;
}

.link-button:hover,
.link-button:focus {
  text-decoration: none;
}

.error-link-button {
  font-size: 12px;
  color: red;
}

.nobreak {
  white-space: nowrap;
}

p.help-p1 {
  font-size: 12px;
  text-align: left;
}

p.help-p2 {
  font-size: 14px;
  text-align: left;
}

p.link {
  color: #1890ff;
}

p.warning {
  color: #c00;
}

strong.warning {
  color: #c00;
}

p.link:hover {
  color: #40a9ff;
}

div.inlineFormItem {
  row-gap: 0px;
  display: inline-block;
  padding-right: 38px;
}

div.inlineFormItem2 {
  row-gap: 0px;
  display: inline-block;
  padding-right: 12px;
}

div.inlineFormItem3 {
  row-gap: 0px;
  display: inline-block;
  padding-right: 6px;
  margin-bottom: 0px;
}

div.formItem {
  margin-bottom: 0px;
}

div.formItem2 {
  margin-bottom: 0px;
}

div.center {
  text-align: center;
  padding-top: 30%;
}

//.ant-form-item-label > label {
//  display: inline !important;
//}

.ant-carousel .slick-slider .slick-dots li button {
  background: gray !important;
}

.ant-carousel .slick-slider .slick-dots li.slick-active button {
  background: gray !important;
}

@keyframes shake {
  0% { transform: translateX(0); }
  25% { transform: translateX(-10px); }
  50% { transform: translateX(10px); }
  75% { transform: translateX(-10px); }
  100% { transform: translateX(0); }
}

.shake {
  animation: shake 0.5s;
}
