@import '../../../assets/styles/index';

p {
  margin-bottom: 0 !important;
}

.container {
  width: 100%;
  border: 1px solid $strokeGray;
  border-radius: 4px;
  padding: 8px;
}

.time-estimate {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 100%;
  border: 1px solid $strokeBlue;
  border-radius: 6px;
  padding: 8px;
}

.label {
  display: flex;
  align-items: center;
  gap: 4px;
  @include main;
  color: $main;
}

.block {
  @include main;
  color: $main;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  text-align: left;
}

.ant-divider {
  margin: 12px 0 !important;
}

.service {
  .name {
    flex: 2;
  }
  .usage {
    flex: 1;
    text-align: right;
  }
  .cost {
    flex: 1;
    text-align: right
  }
}

.discount {
  color: $green;
}