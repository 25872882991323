$system-fonts: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
sans-serif;

$inter: 'Inter', sans-serif;

@mixin font-style($font-weight, $font-size, $letter-spacing, $mobile-font-size: null) {
  font-family: $system-fonts;
  font-weight: $font-weight;
  font-size: $font-size;
  letter-spacing: $letter-spacing;

  @if $mobile-font-size {
    @media (max-width: 768px) {
      @if $mobile-font-size {
        font-size: $mobile-font-size;
      }
    }
  }
}

@mixin main {
  @include font-style(400, 15px, 0);
}

@mixin h1 {
  @include font-style(600, 28px, 0, 22px);
}

@mixin h2 {
  @include font-style(500, 20px, 0, 18px);
}

@mixin h3 {
  @include font-style(400, 18px, 0, 16px);
}

@mixin h4 {
  @include font-style(400, 16px, 0);
}

@mixin button {
  @include font-style(600, 15px, 0);
}

@mixin tag {
  @include font-style(400, 12px, 0);
}
