@import '../../../../assets/styles/index';

.start-task-btn-wrapper {
  position: sticky;
  bottom: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 100;
  padding: 25px 0;
  display: flex;
  align-items: center;
  .start-task {
    width: 400px;
    @media (max-width: 992px) {
      width: 100%;
    }
  }
}
