@import '../../../assets/styles/index';


.taskTable {
  .ant-table {
    @include main;
    color: $main;
  }

  .ant-table-selection-column {
    padding-inline-end: 16px !important;
  }

  .ant-table-cell {
    vertical-align: top;
  }

  .task-actions {
    display: flex;
    justify-content: flex-end;
  }

  .copy-id-btn {
    padding: 0;
    height: unset;
  }
}

.more-actions {
  @include main;
  color: $main;
  background-color: $white;
  z-index: 100;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px;
  border: 1px solid $strokeLightGray;
  border-radius: 8px;
  box-shadow: 3px 4px 9px 0 rgba(144, 143, 166, 0.25);

  button, .ant-badge {
    width: 100%;
  }

  button {
    justify-content: flex-start;
  }
}

.task-table-header-actions {
  width: 100%;
  .ant-space-item:nth-child(2) {
    flex-grow: 1;
  }
}